<template>
  <div>
    <section id="section0" class="h-viewport" style="background: rgb(37,58,168);
background: radial-gradient(circle, rgba(37,58,168,1) 0%, rgba(0,45,105,1) 100%);background:url(/images/anlly/15732031.jpg);background-size:cover">
      <div class="h-100">
        <div class="container h-100 section-dynamic align-items-center">
          <div class="section-content pt-5 p-4">
            
            <div class="form-group row">
              <label class="col-md-3 col-form-label text-md-right"></label>
              <div class="col-md-7">
                <h5>{{$t('Investor Portal')}}</h5>
              </div>
            </div>

            <form @submit.prevent="login" @keydown="form.onKeydown($event)">
              <!-- Email -->
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('email') }}</label>
                <div class="col-md-7">
                  <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control" type="email" name="email">
                  <has-error :form="form" field="email" />
                </div>
              </div>

              <!-- Password -->
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('password') }}</label>
                <div class="col-md-7">
                  <input v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }" class="form-control" type="password" name="password">
                  <has-error :form="form" field="password" />
                </div>
              </div>

              <!-- Remember Me -->
              <!--
              <div class="form-group row">
                <div class="col-md-3" />
                <div class="col-md-7 d-flex">
                   <checkbox v-model="remember" name="remember">
                    {{ $t('remember_me') }}
                  </checkbox> 
                  <router-link :to="{ name: 'password.request' }" class="small ml-auto my-auto">
                    {{ $t('forgot_password') }}
                  </router-link>
                </div>
              </div>-->

              <div class="form-group row">
                <div class="col-md-7 offset-md-3 d-flex">
                  <!-- Submit Button -->
                  <v-button :loading="form.busy">
                    {{ $t('login') }}
                  </v-button>
                </div>
              </div>
            </form>

            <div style="height: 30px; width: 1px"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Form from 'vform'
import Cookies from 'js-cookie'
import LoginWithGithub from '~/components/LoginWithGithub'

export default {
  components: {
    LoginWithGithub
  },

  layout: 'home_investor',
  middleware: 'guest',

  metaInfo () {
    return { title: this.$t('login') }
  },

  data: () => ({
    form: new Form({
      email: '',
      password: ''
    }),
    remember: true
  }),

  methods: {
    async login () {
      // Submit the form.
      const { data } = await this.form.post('https://anllyasset.com/api/login')

      // Save the token.
      this.$store.dispatch('auth/saveToken', {
        token: data.token,
        remember: this.remember
      })

      // Fetch the user.
      await this.$store.dispatch('auth/fetchUser')

      // Redirect home.
      this.redirect()
    },

    redirect () {
      const intendedUrl = Cookies.get('intended_url')

      if (intendedUrl) {
        Cookies.remove('intended_url')
        this.$router.push({ path: intendedUrl })
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>
